import React, { useState, useRef, useEffect } from "react"
import { Formik, Form } from "formik"
import { Button, Label, Input, FormFeedback, Row, Col, Alert } from "reactstrap"
import * as Yup from "yup"
import { SketchPicker } from "react-color"
import Switch from "react-switch"
import axios from "axios"
import { setBreadcrumbItems } from "../../store/actions"
import { connect } from "react-redux"
import { QRCodeCanvas } from "qrcode.react"
const MerchantInfos = () => {
  const [logoPreview, setLogoPreview] = useState(null)
  const [themeColor, setThemeColor] = useState("")
  const [textColor, setTextColor] = useState("")
  const [showThemePicker, setShowThemePicker] = useState(false)
  const [showTextPicker, setShowTextPicker] = useState(false)
  const [hasGst, setHasGst] = useState(true)
  // const [email, setEmail] = useState("")
  const [mobileNo, setMobileNo] = useState("")
  const [gstNumber, setGstNumber] = useState("")
  const [switch1, setswitch1] = useState(false)
  const [submitMessage, setSubmitMessage] = useState("")
  const [qrCodeValue, setQrCodeValue] = useState("")
  const themePickerRef = useRef(null)
  const textPickerRef = useRef(null)

  const businessCategories = [
    "Retail",
    "Food & Beverage",
    "Services",
    "Health & Wellness",
    "Technology",
    "Education",
    "Entertainment",
    "Other",
  ]

  // Fetch email from localStorage on component load
  const email = localStorage.getItem("email")
  console.log("email", email)
  // Validation schema using Yup
  const validationSchema = Yup.object({
    logo: Yup.mixed()
      .required("Logo is required")
      .test(
        "fileSize",
        "File size is too large. Please select an image below 500KB",
        value => {
          return value && value.size <= 500000 // 500kb
        },
      ),

    name: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z\s]+$/, "Name must only contain letters and spaces"),

    businessName: Yup.string()
      .required("Business Name is required")
      .test(
        "uniqueBusinessName",
        "Business name already exists",
        async value => {
          if (!value) return true
          try {
            const response = await axios.post(
              "https://api.postaireview.com/check-unique",
              { businessName: value },
            )
            return response.status === 200 // Valid if unique
          } catch (error) {
            if (error.response) {
              if (error.response.status === 409) {
                return false // Already taken
              }
              console.error("Error response from server:", error.response)
            } else {
              console.error("Error during request:", error)
            }
            throw new Error("Server error during business name validation") // Handle any other errors
          }
        },
      ),

    themeColor: Yup.string().required("Theme Color is required"),
    textColor: Yup.string().required("Text Color is required"),
    googleReviewLink: Yup.string()
      .required("Google Review Link is required")
      .url("Enter a valid URL"),
    negativeReviewMessage: Yup.string().required(
      "Negative Review Message is required",
    ),
    businessCategory: Yup.string().required("Business Category is required"),

    gstNumber: hasGst
      ? Yup.string()
          .matches(/^[A-Z0-9]+$/, "GST Number must be alphanumeric")
          .required("GST Number is required")
      : Yup.string().nullable(),
  })

  const handleLogoChange = (event, setFieldValue) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setLogoPreview(reader.result)
        setFieldValue("logo", file)
      }
      reader.readAsDataURL(file)
    } else {
      setLogoPreview(null)
      setFieldValue("logo", null)
    }
  }

  const handleThemeColorChange = (color, setFieldValue) => {
    setThemeColor(color.hex)
    setFieldValue("themeColor", color.hex)
  }
  const handleGstToggle = () => {
    setHasGst(prev => !prev)
    if (hasGst) {
      setGstNumber("")
    }
  }

  const handleTextColorChange = (color, setFieldValue) => {
    setTextColor(color.hex)
    setFieldValue("textColor", color.hex)
  }

  const OnSymbol = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      On{" "}
    </div>
  )

  const Offsymbol = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Off{" "}
    </div>
  )

  const handleSubmit = async values => {
    const formData = new FormData()
    formData.append("logo", values.logo)
    formData.append("themeColor", values.themeColor)
    formData.append("textColor", values.textColor)
    formData.append("googlereviewURL", values.googleReviewLink)
    formData.append("negativeRevireMessage", values.negativeReviewMessage)
    formData.append("name", values.name)
    formData.append("businessname", values.businessName)
    formData.append("email", email)
    formData.append("mobileNo", values.mobileNo)
    formData.append("businessCategory", values.businessCategory)
    formData.append("gstNumber", hasGst ? values.gstNumber : "")
    formData.append(
      "scanner",
      setQrCodeValue(`https://test.postaireview.com/${values.businessName}`),
    )
    formData.append("negativeReviewProtectiontoggle", switch1)

    // try {
    //   const token = localStorage.getItem("token") // Fetch the token from localStorage

    //   const response = await axios.put(
    //     `https://api.postaireview.com/merchantsinfosByEmail/${email}`,
    //     formData,
    // {
    // headers: {
    //   "Content-Type": "multipart/form-data",
    //   Authorization: `Bearer ${token}`, // Include the token here
    // },
    //     },
    //   )

    //   setSubmitMessage("Data submitted successfully!")
    //   console.log("Form data:", response.data)
    // } catch (error) {
    //   setSubmitMessage(
    //     "Submission failed. Please check your input and try again.",
    //   )
    // }

    //updated code
    try {
      // Fetch the token from localStorage
      const token = localStorage.getItem("token")
      const chunkSize = 1000 // Define the chunk size for splitting
      const headers = {
        "Content-Type": "multipart/form-data", // Base header
      }

      // Split the token into smaller parts and add to headers
      if (token) {
        for (let i = 0; i < token.length; i += chunkSize) {
          headers[`Authorization-Part-${i / chunkSize}`] = token.substring(
            i,
            i + chunkSize,
          )
        }
      }

      // Make the axios PUT request with split token headers
      const response = await axios.put(
        `https://api.postaireview.com/merchantsinfosByEmail/${email}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token here
          },
        },
      )

      // Handle success
      setSubmitMessage("Data submitted successfully!")
      console.log("Form data:", response.data)
    } catch (error) {
      // Handle error
      setSubmitMessage(
        "Submission failed. Please check your input and try again.",
      )
      console.error("Error during submission:", error)
    }
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        themePickerRef.current &&
        !themePickerRef.current.contains(event.target)
      ) {
        setShowThemePicker(false)
      }
      if (
        textPickerRef.current &&
        !textPickerRef.current.contains(event.target)
      ) {
        setShowTextPicker(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <Formik
      initialValues={{
        logo: null,
        themeColor: "",
        textColor: "",
        googleReviewLink: "",
        negativeReviewMessage: "",
        name: "",
        businessName: "",
        businessCategory: "",
        gstNumber: "",
        email: email,
        mobileNo: "",
        scanner: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Row>
            <Col md="8">
              {submitMessage && (
                <Alert
                  color="success"
                  style={{ backgroundColor: "#d4edda", borderColor: "#c3e6cb" }}
                >
                  {submitMessage}
                </Alert>
              )}

              {/* Logo Upload Field */}
              <div className="form-group mt-3">
                <Label>Upload Your Logo</Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    name="logo"
                    type="file"
                    accept="image/jpeg,image/png"
                    onChange={event => handleLogoChange(event, setFieldValue)}
                    id="logoUpload"
                    style={{ display: "none" }}
                  />
                  <Button
                    color="primary"
                    onClick={() =>
                      document.getElementById("logoUpload").click()
                    }
                  >
                    Upload Logo
                  </Button>
                  <span style={{ marginLeft: "10px" }}>
                    {logoPreview ? "Logo uploaded!" : "No logo uploaded."}
                  </span>
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      border: "1px solid #ccc",
                      borderRadius: "3px",
                      marginLeft: "20px",
                      backgroundColor: "#f5f5f5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {logoPreview ? (
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <span style={{ color: "#ccc" }}>No logo</span>
                    )}
                  </div>
                </div>
                {touched.logo && errors.logo ? (
                  <FormFeedback style={{ display: "block" }}>
                    {errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              {/*Name Field */}
              <div className="mb-3">
                <Label htmlFor="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.name && !!errors.name}
                />
                {touched.name && errors.name && (
                  <FormFeedback>{errors.name}</FormFeedback>
                )}
              </div>

              {/* Business Name Field */}
              <div className="mb-3">
                <Label htmlFor="businessName">Business Name</Label>
                <Input
                  type="text"
                  name="businessName"
                  id="businessName"
                  placeholder="Enter your Business Name"
                  value={values.businessName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.businessName && !!errors.businessName}
                />
                {touched.businessName && errors.businessName && (
                  <FormFeedback>{errors.businessName}</FormFeedback>
                )}
              </div>

              {/* Business Category Dropdown */}
              <div className="mb-3">
                <Label htmlFor="businessCategory">Business Category</Label>
                <Input
                  type="select"
                  name="businessCategory"
                  id="businessCategory"
                  value={values.businessCategory}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    touched.businessCategory && !!errors.businessCategory
                  }
                >
                  <option value="" label="Select business category" />
                  {businessCategories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Input>
                {touched.businessCategory && errors.businessCategory && (
                  <FormFeedback>{errors.businessCategory}</FormFeedback>
                )}
              </div>

              {/* GST Number Field */}
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    onChange={handleGstToggle}
                    className="form-check-input"
                    checked={!hasGst}
                  />
                  <label className="form-check-label mb-3">
                    I don't have a GST number
                  </label>
                </div>

                <Label htmlFor="gstNumber">GST Number</Label>
                <Input
                  id="gstNumber"
                  name="gstNumber"
                  type="text"
                  placeholder="Enter GST number"
                  value={values.gstNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!hasGst}
                  invalid={touched.gstNumber && !!errors.gstNumber}
                />
                {touched.gstNumber && errors.gstNumber && (
                  <FormFeedback style={{ display: "block" }}>
                    {errors.gstNumber}
                  </FormFeedback>
                )}
              </div>

              {/* Email Field */}
              <div className="mb-3">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your Email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.email && !!errors.email}
                  readOnly
                  style={{ backgroundColor: "#f0f0f0" }}
                />
                {touched.email && errors.email && (
                  <FormFeedback>{errors.email}</FormFeedback>
                )}
              </div>

              <div className="mb-3">
                <Label htmlFor="mobileNo">Mobile Number</Label>
                <Input
                  type="number"
                  name="mobileNo"
                  id="mobileNo"
                  placeholder="Enter your mobile number"
                  value={values.mobileNo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.mobileNo && !!errors.mobileNo}
                />
                {touched.mobileNo && errors.mobileNo && (
                  <FormFeedback>{errors.mobileNo}</FormFeedback>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              {/* Theme and Text Color Fields */}
              <div
                className="mb-3"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ marginRight: "20px", flex: "1 1 200px" }}>
                  <Label>Theme Color</Label>
                  <Input
                    type="text"
                    placeholder="Select Theme Color"
                    onClick={() => setShowThemePicker(!showThemePicker)}
                    value={themeColor}
                    readOnly
                    invalid={touched.themeColor && !!errors.themeColor}
                  />
                  {showThemePicker && (
                    <div ref={themePickerRef}>
                      <SketchPicker
                        color={themeColor}
                        onChangeComplete={color =>
                          handleThemeColorChange(color, setFieldValue)
                        }
                      />
                    </div>
                  )}
                  {touched.themeColor && errors.themeColor ? (
                    <FormFeedback style={{ display: "block" }}>
                      {errors.themeColor}
                    </FormFeedback>
                  ) : null}

                  <Label className="mt-3">Text Color</Label>
                  <Input
                    type="text"
                    placeholder="Select Text Color"
                    onClick={() => setShowTextPicker(!showTextPicker)}
                    value={textColor}
                    readOnly
                    invalid={touched.textColor && !!errors.textColor}
                  />
                  {showTextPicker && (
                    <div ref={textPickerRef}>
                      <SketchPicker
                        color={textColor}
                        onChangeComplete={color =>
                          handleTextColorChange(color, setFieldValue)
                        }
                      />
                    </div>
                  )}
                  {touched.textColor && errors.textColor ? (
                    <FormFeedback style={{ display: "block" }}>
                      {errors.textColor}
                    </FormFeedback>
                  ) : null}
                </div>

                {/* Preview Button */}
                <div
                  style={{
                    marginTop: "20px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: "1 1 150px",
                  }}
                >
                  <Button
                    disabled
                    style={{
                      backgroundColor: themeColor,
                      borderColor: themeColor,
                      color: textColor,
                      marginRight: "10px",
                    }}
                  >
                    Post Review
                  </Button>
                  <div
                    style={{
                      borderLeft: "1px solid #ccc",
                      height: "60px",
                      margin: "0 10px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#000",
                      fontWeight: "lighter",
                    }}
                  >
                    This is a preview button and it will be visible to the
                    person submitting review.
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              {/* Google Review Link Field */}
              <div className="mb-3">
                <Label htmlFor="googleReviewLink">Google Review Link</Label>
                <Input
                  type="url"
                  name="googleReviewLink"
                  id="googleReviewLink"
                  placeholder="Enter your Google Review Link"
                  value={values.googleReviewLink}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    touched.googleReviewLink && !!errors.googleReviewLink
                  }
                />
                {touched.googleReviewLink && errors.googleReviewLink && (
                  <FormFeedback>{errors.googleReviewLink}</FormFeedback>
                )}
              </div>

              {/* Negative Review Message */}
              <div className="mb-3">
                <Label htmlFor="negativeReviewMessage">
                  Negative Review Message
                </Label>
                <Input
                  type="textarea"
                  name="negativeReviewMessage"
                  id="negativeReviewMessage"
                  placeholder="Enter message for negative reviews"
                  value={values.negativeReviewMessage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={
                    touched.negativeReviewMessage &&
                    !!errors.negativeReviewMessage
                  }
                />
                {touched.negativeReviewMessage &&
                  errors.negativeReviewMessage && (
                    <FormFeedback>{errors.negativeReviewMessage}</FormFeedback>
                  )}
              </div>

              {/* Negative Review Protection Switch */}
              <div className="form-group mt-3">
                <Label className="form-label">
                  Enable Negative Review Protection
                </Label>
                <br />
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#7A6FBE"
                  onChange={() => setswitch1(!switch1)}
                  checked={switch1}
                />
                &nbsp;
              </div>
            </Col>
          </Row>

          <Button
            color="primary"
            type="submit"
            className="mt-3"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default MerchantInfos
