import React, { useEffect, useState } from "react"
import { Row, Col, Card, Input, Spinner } from "reactstrap"
import { connect } from "react-redux"
import { Button, CardBody, CardTitle, CardText } from "reactstrap"
import { useParams } from "react-router-dom"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

//Import Email Sidebar
// import EmailSideBar from "./email-sidebar"
import { Editor } from "react-draft-wysiwyg"
import axios from "axios"
const Dashboard = props => {
  document.title = "Dashborad | Lexa - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Dashborad", link: "/dashboard" },
  ]
  // const { businessname } = useParams() // Extracting `businessname` from the URL
  const [merchantData, setMerchantData] = useState(null) // State to store merchant data
  const [loading, setLoading] = useState(true) // Loading state
  const [error, setError] = useState(null) // Error state
  const email = localStorage.getItem("email")
  console.log("businessNameOrEmail", email)
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Dashborad", breadcrumbItems)
    // Fetch data from the API
    const fetchMerchantData = async () => {
      try {
        if (!email) {
          setError("email is required")
          return
        }

        const response = await axios.get(
          `https://api.postaireview.com/merchantsinfos/${email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass token in header
              "Content-Type": "application/json",
            },
          },
        )

        if (response && response.data) {
          setMerchantData(response.data) // Set fetched data to state
        } else {
          setError("No data found")
        }
      } catch (error) {
        console.error("Error fetching merchant data:", error)
        setError("Failed to fetch merchant data")
      } finally {
        setLoading(false)
      }
    }

    fetchMerchantData()
  }, [email, setBreadcrumbItems])
  const reports = [
    {
      title: "Positive Reviews",
      iconClass: "thumb-up",
      total: "125",
      average: "+15%",
      badgecolor: "success",
    },
    {
      title: "Negative Reviews",
      iconClass: "thumb-down",
      total: "50",
      average: "-5%",
      badgecolor: "danger",
    },
    {
      title: "Total QR Code Scans",
      iconClass: "qrcode-scan",
      total: "150",
      average: "+20%",
      badgecolor: "primary",
    },
  ]

  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner size="lg" className="me-2" style={{ color: "#2B3A4A" }} />
          Loading...
        </div>
      </>
    )
  }

  if (error) {
    return <p>{error}</p>
  }
  return (
    <React.Fragment>
      {/* Mini widgets */}
      <Miniwidget reports={reports} />

      <Row>
        <Col xl="4">
          {/* Monthly Earnings */}
          <MonthlyEarnings merchantData={merchantData} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
