// import React, { useEffect } from "react"
// import PropTypes from "prop-types"
// import withRouter from "components/Common/withRouter"
// import { logoutUser } from "../../store/actions"

// //redux
// import { useDispatch } from "react-redux"
// import { useNavigate } from "react-router-dom"

// const Logout = () => {
//   const history = useNavigate()
//   const dispatch = useDispatch()

//   useEffect(() => {
//     dispatch(logoutUser(history))
//   }, [dispatch, history])

//   return <></>
// }

// Logout.propTypes = {
//   history: PropTypes.object,
// }

// export default withRouter(Logout)

import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // Clear user data from localStorage
    localStorage.removeItem("token")
    localStorage.removeItem("email")

    // Redirect to the login page
    navigate("/login")
  }, [navigate])

  return null // No UI needed
}

export default Logout
