// import { Navigate } from "react-router-dom"
// import React, { useEffect, useState } from "react"
// import { Navigate } from "react-router-dom"
// import axios from "axios"
// const Authmiddleware = props => {
//   const token = localStorage.getItem("token")
//   if (!token) {
//     //call the api verify token payload should be
//     // token
//     // url :`https://api.postaireview.com/verifyToken/${token}`

//   }
// //   const response = await axios.post(
// //   `https://api.postaireview.com/verifytoken/${token}`,
// // )
// // if (response.status== 200){
// //   return <React.Fragment>{props.children}</React.Fragment>
// // }else{
// //   return (
// //     <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
// //   )
// // }

// const verifyToken = async () => {
//   try {

//     // Call the API to verify the token
//     const response = await axios.post(
//       `https://api.postaireview.com/verifytoken/${token}`,
//     )
//     if (response.status== 200){
//       return <React.Fragment>{props.children}</React.Fragment>
//     }else{
// return (
//   <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
// )
//     }

//   }
// }

// }

// export default Authmiddleware

// import React, { useEffect } from "react"
// import { Navigate } from "react-router-dom"
// import axios from "axios"

// const Authmiddleware = props => {
//   const token = localStorage.getItem("token")

//   useEffect(() => {
//     const verifyToken = async () => {
//       try {
//         if (!token) {
//           console.error("Token is missing!")

//           return
//         }

//         // Call the API to verify the token
//         const response = await axios.post(
//           `https://api.postaireview.com/verifytoken/${token}`,
//         )

//         if (response.status === 200) {
//           return <React.Fragment>{props.children}</React.Fragment>
//         } else {
//           console.error(
//             "Token verification failed with status:",
//             response.status,
//           )
//           return (
//             <Navigate
//               to={{ pathname: "/login", state: { from: props.location } }}
//             />
//           )
//         }
//       } catch (error) {
//         console.error("Token verification failed:", error.message)
//         console.error("Error response data:", error.response?.data)
//       }
//     }

//     verifyToken()
//   }, [token])
// }

// export default Authmiddleware

import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import axios from "axios"

const Authmiddleware = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(null) // State to track authentication
  const token = localStorage.getItem("token")
  console.log("token", token)
  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!token) {
          console.error("Token is missing!")
          setIsAuthenticated(false) // Redirect if token is not found
          return
        }

        // API call to verify token with Bearer token in the Authorization header
        const response = await axios.post(
          "https://api.postaireview.com/verifytoken", // Ensure the endpoint is correct
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass token in header
              "Content-Type": "application/json",
            },
          },
        )

        if (response.status === 200) {
          console.log("Token verified successfully:", response.data)
          setIsAuthenticated(true) // Allow access
        } else {
          console.error(
            "Token verification failed with status:",
            response.status,
          )
          setIsAuthenticated(false) // Redirect on failure
        }
      } catch (error) {
        console.error("Token verification failed:", error.message)
        console.error("Error response data:", error.response?.data)
        setIsAuthenticated(false) // Redirect on error
      }
    }

    verifyToken()
  }, [token])

  // Show a loading message while authentication is being verified
  if (isAuthenticated === null) {
    return <div>Loading...</div>
  }

  // Redirect to login if authentication fails
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }

  // Render child components if authentication succeeds
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
