import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"

// import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/Logo.png"
// const jwt = require("jsonwebtoken")
const Login = () => {
  document.title = "Login | Lexa - Responsive Bootstrap 5 Admin Dashboard"
  // useEffect(() => {
  //   const token = localStorage.getItem("token")
  //   if (token) {
  //     navigate("/dashboard") // Redirect to dashboard if already logged in
  //   }
  // }, [])
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null) // State to manage success message
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async values => {
      await loginUser(values)
    },
  })

  const loginUser = async values => {
    const { email, password } = values

    try {
      setLoading(true)
      setError(null)
      setSuccess(null) // Clear previous success messages

      // Make login request to the server
      const response = await axios.post(
        "https://api.postaireview.com/login",
        { email, password },
        {
          headers: { "Content-Type": "application/json" },
        },
      )

      if (response.data && response.data.auth) {
        // Save the server-generated token in localStorage
        localStorage.setItem("token", response.data.auth)

        localStorage.setItem("email", response.data.merchantsInfo.email)

        setSuccess("Login successful! Redirecting to the dashboard...")

        // Navigate to the Dashboard after a brief delay
        setTimeout(() => {
          navigate("/dashboard")
        }, 2000)
      } else {
        throw new Error("Invalid login response")
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message || "Login failed, please try again.")
      } else if (err.request) {
        setError("Network error: Could not reach the server. Please try again.")
      } else {
        setError("An unknown error occurred.")
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <h3 className="text-center mt-5 mb-4">
                  <Link to="/" className="d-block auth-logo">
                    <img
                      src={logoLight}
                      alt=""
                      height="70"
                      className="auth-logo-dark"
                    />
                    <img
                      src={logoLight}
                      alt=""
                      height="70"
                      className="auth-logo-light"
                    />
                  </Link>
                </h3>
                <div className="p-3">
                  <h4 className="text-muted font-size-18 mb-1 text-center">
                    Welcome Back!
                  </h4>
                  <p className="text-muted text-center">
                    Sign in to continue to Lexa.
                  </p>
                  <Form
                    className="form-horizontal mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                    }}
                  >
                    {error && <Alert color="danger">{error}</Alert>}
                    {success && <Alert color="success">{success}</Alert>}
                    <div className="mb-3">
                      <Label htmlFor="email">Email Address</Label>
                      <Input
                        name="email"
                        placeholder="Enter email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email}
                        invalid={
                          validation.touched.email && validation.errors.email
                        }
                      />
                      {validation.touched.email && validation.errors.email && (
                        <FormFeedback>{validation.errors.email}</FormFeedback>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        name="password"
                        placeholder="Enter password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                        }
                      />
                      {validation.touched.password &&
                        validation.errors.password && (
                          <FormFeedback>
                            {validation.errors.password}
                          </FormFeedback>
                        )}
                    </div>

                    <Row className="mb-3 mt-4">
                      <div className="col-6">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <Spinner size="sm" className="me-2" />
                              Logging In...
                            </>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                    </Row>

                    <Row className="form-group mb-0">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock"></i> Forgot your password?
                      </Link>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Don't have an account?{" "}
                <Link to="/register" className="text-primary">
                  Signup Now
                </Link>
              </p>
              {/* © {new Date().getFullYear()} Lexa{" "}
              <span className="d-none d-sm-inline-block">
                {" "}
                - Crafted with <i className="mdi mdi-heart text-danger"></i> by
                Themesbrand.
              </span> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login
