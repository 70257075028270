import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import logoDark from "../../assets/images/Logo.png"
import logoLight from "../../assets/images/Logo.png"

const SetPassword = () => {
  document.title =
    "Set Password | Lexa - Responsive Bootstrap 5 Admin Dashboard"

  const [apiError, setApiError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const navigate = useNavigate()
  const [activationLinkId, setActivationLinkId] = useState("")

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href

    // Extract the ID from the URL by splitting it
    const id = currentUrl.split("id=").pop() // Use "id=" as the delimiter

    if (id) {
      // Log the extracted ID for debugging purposes
      console.log("Activation Link ID: ", id)

      // Set the activation link ID to use in the request
      setActivationLinkId(id)
    } else {
      setApiError("Invalid or missing activation link.")
    }
  }, [])

  const validation = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Please Enter Your Password"),
      newPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Confirm Your Password"),
    }),
    onSubmit: async values => {
      if (!activationLinkId) {
        setApiError("Invalid or missing activation link.")
        return
      }

      try {
        const response = await fetch(
          `https://api.postaireview.com/setPassword/${activationLinkId}`, // Using the ID from URL
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: values.password,
            }),
          },
        )

        if (response.ok) {
          const result = await response.json()
          setSuccessMessage(result.message || "Password set successfully.")
          setTimeout(() => navigate("/login"), 2000)
          setApiError("")
        } else {
          const error = await response.json()
          setApiError(error.error || "Password set failed.")
          setSuccessMessage("")
        }
      } catch (error) {
        setApiError("An error occurred. Please try again.")
        setSuccessMessage("")
      }
    },
  })

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <h3 className="text-center mt-5 mb-4">
                  <Link to="/" className="d-block auth-logo">
                    <img
                      src={logoDark}
                      alt=""
                      height="70"
                      className="auth-logo-dark"
                    />
                    <img
                      src={logoLight}
                      alt=""
                      height="70"
                      className="auth-logo-light"
                    />
                  </Link>
                </h3>
                <div className="p-3">
                  <h4 className="text-muted font-size-18 mb-3 text-center">
                    Set Password
                  </h4>
                  {apiError && (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {apiError}
                    </Alert>
                  )}
                  {successMessage && (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {successMessage}
                    </Alert>
                  )}
                  <form
                    className="form-horizontal mt-4"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                    }}
                  >
                    <div className="mb-3">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password &&
                          validation.errors.password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.password &&
                        validation.errors.password && (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        )}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="newPassword">Confirm Password</Label>
                      <Input
                        name="newPassword"
                        type="password"
                        placeholder="Confirm Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newPassword || ""}
                        invalid={
                          validation.touched.newPassword &&
                          validation.errors.newPassword
                            ? true
                            : false
                        }
                      />
                      {validation.touched.newPassword &&
                        validation.errors.newPassword && (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        )}
                    </div>

                    <Row className="mb-3">
                      <div className="col-12 text-end">
                        <button
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Set Password
                        </button>
                      </div>
                    </Row>
                  </form>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                Remember It?{" "}
                <Link to="/login" className="text-primary">
                  Sign In Here
                </Link>
              </p>
              {/* © {new Date().getFullYear()} Lexa{" "}
              <span className="d-none d-sm-inline-block">
                - Crafted with <i className="mdi mdi-heart text-danger"></i> by
                Themesbrand.
              </span> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SetPassword
