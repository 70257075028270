import React, { useState, useEffect } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
// import Logo from "../../assets/images/Jackmex.jpg"
const AIReviewCreation = () => {
  const [activeStars, setActiveStars] = useState(0)
  const [buttonLabel, setButtonLabel] = useState("Generate AI Review")
  const [clickedDishesKeywords, setClickedDishesKeywords] = useState([])
  const [clickedReviewKeywords, setClickedReviewKeywords] = useState([])
  const [showAllDishesKeywords, setShowAllDishesKeywords] = useState(false)
  const [showAllReviewKeywords, setShowAllReviewKeywords] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [copySuccess, setCopySuccess] = useState(false)

  const [queMerchData, setQueMerchData] = useState({})

  const [generatedText, setGeneratedText] = useState("")

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [reviewloading, setReviewLoading] = useState(false)
  const [error, setError] = useState(null)
  const [logoUrl, setLogoUrl] = useState("")
  const params = useParams()

  const { businessname } = params
  console.log("params", params)

  useEffect(() => {
    if (businessname) {
      const fetchQueMerchData = async () => {
        try {
          setLoading(true)
          const response = await axios.get(
            `https://api.postaireview.com/api/getData/${businessname}`,
          )
          if (response && response.data) {
            setQueMerchData(response.data)
          } else {
            setError("No reviews found")
          }
        } catch (error) {
          setError(`${navigate("/pages-404")}`)
        } finally {
          setLoading(false)
        }
      }

      fetchQueMerchData()
    }
  }, [businessname]) // Moved the dependency array outside the function

  useEffect(() => {
    if (typeof queMerchData?.MerchantsTable?.logo === "string") {
      setLogoUrl(queMerchData?.MerchantsTable?.logo) // Directly use the string URL
    } else if (queMerchData?.MerchantsTable?.logo?.data) {
      // If it's a buffer, convert it to a Blob URL
      const convertBufferToBlobUrl = bufferData => {
        const blob = new Blob([new Uint8Array(bufferData)], {
          type: "image/jpeg",
        }) // Adjust MIME type as needed
        return URL.createObjectURL(blob)
      }

      const logoUrl = convertBufferToBlobUrl(
        queMerchData.MerchantsTable.logo.data,
      )

      setLogoUrl(logoUrl)
    }
  })
  console.log("queMerchData", queMerchData)

  // Array of rating descriptions

  // Toggle dish keyword selection
  const toggleDishKeyword = keyword => {
    setClickedDishesKeywords(prev =>
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword],
    )
  }

  // Toggle review keyword selection
  const toggleReviewKeyword = keyword => {
    setClickedReviewKeywords(prev =>
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword],
    )
  }

  const handleStarClick = star => {
    setActiveStars(star)
  }
  localStorage.setItem("activeStars", activeStars)

  const handleButtonClick = async () => {
    const selectedKeywords1 = clickedDishesKeywords.join(", ")
    const selectedKeywords2 = clickedReviewKeywords.join(", ")
    setHasSubmitted(true)
    setButtonLabel("Regenerate AI Review")

    if (activeStars === 0) return
    if (clickedDishesKeywords.length === 0) return
    if (clickedReviewKeywords.length === 0) return

    const message = `Consider yourself as customer and give a human touch in responses
          (Don't mention business name in the answer, also remove double quotes)
         for google review.Write a ${activeStars} star review in 30 words about
         ${queMerchData.MerchantsTable?.businessname} having business category is
          ${queMerchData.MerchantsTable?.businessCategory}. Questions are as following 1)
           ${queMerchData.QuestionTable.question1} and
        respective selected keywords for Question1 are ${selectedKeywords1}
         2) ${queMerchData.QuestionTable.question2} and respective selective
         keywords for keywords for Question2 are ${selectedKeywords2}.generate response only in paragraph`

    const apikey = process.env.REACT_APP_OPENAI_API_KEY

    try {
      setReviewLoading(true) // Set loading to true
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [{ role: "user", content: message }],
          max_tokens: 200,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${apikey}`,
          },
        },
      )

      setGeneratedText(
        response?.data?.choices[0]?.message?.content || "No review generated.",
      )
    } catch (error) {
      console.error("Error generating review:", error)
      setGeneratedText("Error generating review.")
    } finally {
      setReviewLoading(false) // Set loading to false when the request is done
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(generatedText)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000)
      })
      .catch(err => {
        console.error("Failed to copy: ", err)
      })
    if (activeStars <= 3) {
      navigate("/negative-review-form")
    } else {
      window.open(queMerchData.MerchantsTable.googlereviewURL)
    }
  }
  if (loading) {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spinner size="xg" className="me-2" style={{ color: "#2B3A4A" }} />
          Loading...
        </div>
      </>
    )
  }

  if (error) {
    return <p>{error}</p>
  }
  return (
    <Container className="my-2">
      {/* Header Section */}
      <Row className="text-center">
        <Col>
          {logoUrl ? (
            <img
              src={logoUrl}
              // src={Logo}
              alt="Logo"
              className="img-fluid"
              style={{ height: "140px" }}
            />
          ) : (
            <p>Logo not available...</p>
          )}
        </Col>
      </Row>
      {/* Star Rating Section */}
      <Row className="text-center mb-1">
        <Col>
          <div className="d-flex justify-content-center">
            {[1, 2, 3, 4, 5].map(star => (
              <i
                className={`mdi ${activeStars >= star ? "mdi-star" : "mdi-star-outline"}`}
                key={star}
                onClick={() => handleStarClick(star)}
                style={{
                  fontSize: "clamp(40px, 5vw, 80px)",
                  cursor: "pointer",
                  margin: "0 10px",
                  color: activeStars >= star ? "gold" : "gray",
                  transition: "transform 0.3s, color 0.3s",
                  transform:
                    activeStars >= star ? "rotate(50deg)" : "rotate(0deg)",
                }}
              />
            ))}
          </div>

          {hasSubmitted && activeStars === 0 && (
            <div style={{ color: "red" }}>Please select a star rating.</div>
          )}
        </Col>
      </Row>

      <Row className="mb-3" style={{ width: "100%", justifyContent: "center" }}>
        <Col xl="6">
          <div className="mb-4">
            <h4>{queMerchData.QuestionTable.question1}</h4>
            <div className="d-flex flex-wrap justify-content-start align-items-center">
              {queMerchData.QuestionTable.question1Keywords
                .slice(
                  0,
                  showAllDishesKeywords
                    ? queMerchData.QuestionTable.question1Keywords.length
                    : 6,
                )
                .map(keyword => (
                  <div key={keyword} style={{ margin: "5px" }}>
                    <div
                      className={`w-auto ${
                        clickedDishesKeywords.includes(keyword)
                          ? "btn-info"
                          : "btn-outline-secondary"
                      }`}
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: clickedDishesKeywords.includes(keyword)
                          ? `rgba(${parseInt(queMerchData.MerchantsTable.themeColor.slice(1, 3), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(3, 5), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(5, 7), 16)}, 0.2)` // 50% opacity
                          : "transparent",
                        color: "black",
                        transition:
                          "background-color 0.3s, color 0.3s, font-weight 0.3s, border-color 0.3s",
                        fontWeight: clickedDishesKeywords.includes(keyword)
                          ? "bold"
                          : "normal",
                      }}
                      onMouseEnter={e => {
                        e.target.style.backgroundColor =
                          "rgba(100, 60, 100, 0.1)"
                        e.target.style.border = clickedDishesKeywords.includes(
                          keyword,
                        )
                          ? "none"
                          : "2px solid gray"
                      }}
                      onMouseLeave={e => {
                        e.target.style.backgroundColor =
                          clickedDishesKeywords.includes(keyword)
                            ? `rgba(${parseInt(queMerchData.MerchantsTable.themeColor.slice(1, 3), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(3, 5), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(5, 7), 16)}, 0.2)` // Maintain 50% opacity on mouse leave
                            : "transparent"
                        e.target.style.border = "1px solid gray"
                      }}
                      onClick={() => toggleDishKeyword(keyword)}
                    >
                      <span
                        role="img"
                        aria-label="check"
                        className="mr-2"
                        style={{
                          color: clickedDishesKeywords.includes(keyword)
                            ? queMerchData.MerchantsTable.themeColor
                            : "transparent",
                        }}
                      >
                        {clickedDishesKeywords.includes(keyword) ? "✓" : ""}
                      </span>{" "}
                      &nbsp;
                      {keyword}
                    </div>
                  </div>
                ))}
              {!showAllDishesKeywords &&
                queMerchData.QuestionTable.question1Keywords.length > 6 && (
                  <div style={{ margin: "5px" }}>
                    <Button
                      color="link"
                      onClick={() =>
                        setShowAllDishesKeywords(!showAllDishesKeywords)
                      }
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: "rgba(100, 60, 100, 0.1)",
                        color: "#014f6d",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                    >
                      {showAllDishesKeywords
                        ? "Show Less"
                        : `+${queMerchData.QuestionTable.question1Keywords.length - 6}`}
                    </Button>
                  </div>
                )}
            </div>

            {hasSubmitted && clickedDishesKeywords.length === 0 && (
              <div style={{ color: "red" }}>
                Please select at least one dish keyword.
              </div>
            )}
          </div>

          <div className="mb-4">
            <h4>{queMerchData.QuestionTable.question2}</h4>
            <div className="d-flex flex-wrap justify-content-start align-items-center">
              {queMerchData.QuestionTable.question2Keywords
                .slice(
                  0,
                  showAllReviewKeywords
                    ? queMerchData.QuestionTable.question2Keywords.length
                    : 6,
                )
                .map(keyword => (
                  <div key={keyword} style={{ margin: "5px" }}>
                    <div
                      className={`w-auto ${
                        clickedReviewKeywords.includes(keyword)
                          ? "btn-info"
                          : "btn-outline-secondary"
                      }`}
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: clickedReviewKeywords.includes(keyword)
                          ? `rgba(${parseInt(queMerchData.MerchantsTable.themeColor.slice(1, 3), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(3, 5), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(5, 7), 16)}, 0.2)`
                          : "transparent",
                        color: "black",
                        transition:
                          "background-color 0.3s, color 0.3s, font-weight 0.3s, border-color 0.3s",
                        fontWeight: clickedReviewKeywords.includes(keyword)
                          ? "bold"
                          : "normal",
                      }}
                      onMouseEnter={e => {
                        e.target.style.backgroundColor =
                          "rgba(100, 60, 100, 0.1)"
                        e.target.style.border = clickedReviewKeywords.includes(
                          keyword,
                        )
                          ? "none"
                          : "2px solid gray"
                      }}
                      onMouseLeave={e => {
                        e.target.style.backgroundColor =
                          clickedReviewKeywords.includes(keyword)
                            ? `rgba(${parseInt(queMerchData.MerchantsTable.themeColor.slice(1, 3), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(3, 5), 16)}, ${parseInt(queMerchData.MerchantsTable.themeColor.slice(5, 7), 16)}, 0.2)` // Maintain 50% opacity on mouse leave
                            : "transparent"
                        e.target.style.border = "1px solid gray"
                      }}
                      onClick={() => toggleReviewKeyword(keyword)}
                    >
                      <span
                        role="img"
                        aria-label="check"
                        className="mr-2"
                        style={{
                          color: clickedReviewKeywords.includes(keyword)
                            ? queMerchData.MerchantsTable.themeColor
                            : "transparent",
                        }}
                      >
                        {clickedReviewKeywords.includes(keyword) ? "✓" : ""}
                      </span>
                      &nbsp;
                      {keyword}
                    </div>
                  </div>
                ))}
              {!showAllReviewKeywords &&
                queMerchData.QuestionTable.question2Keywords.length > 6 && (
                  <div style={{ margin: "5px" }}>
                    <Button
                      color="link"
                      onClick={() =>
                        setShowAllReviewKeywords(!showAllReviewKeywords)
                      }
                      style={{
                        border: "1px solid gray",
                        borderRadius: "5px",
                        padding: "10px 15px",
                        backgroundColor: "rgba(100, 60, 100, 0.2)",
                        color: "#014f6d",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                    >
                      {showAllReviewKeywords
                        ? "Show Less"
                        : `+${queMerchData.QuestionTable.question2Keywords.length - 6}`}
                    </Button>
                  </div>
                )}
            </div>
            {hasSubmitted && clickedReviewKeywords.length === 0 && (
              <div style={{ color: "red" }}>
                Please select at least one review keyword.
              </div>
            )}
          </div>
        </Col>
      </Row>

      {/* Generate Review Button */}

      <Row className="justify-content-center text-center">
        <Col xs="8" md="6" lg="4">
          <Button
            onClick={handleButtonClick}
            className="generate-button"
            style={{
              backgroundColor: queMerchData.MerchantsTable.themeColor,
              color: queMerchData.MerchantsTable.textColor,
              fontSize: "18px",
              padding: "10px 10px",
              width: "100%",
              opacity: buttonLabel === "Regenerate AI Review" ? 0.5 : 1,
              transition: "opacity 0.3s",
            }}
          >
            {buttonLabel}
          </Button>
        </Col>
      </Row>

      {/* <Row className="mt-4 d-flex justify-content-center">
        <Col xl="8" lg="10" md="12">
          <Card className="border-0" style={{ minHeight: "150px" }}>
            <CardBody>
              <p style={{ maxHeight: "auto", overflowY: "auto" }}>
                {generatedText}

              </p>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row className="mt-4 d-flex justify-content-center">
        <Col xl="8" lg="10" md="12">
          <Card className="border-0" style={{ minHeight: "150px" }}>
            <CardBody className="d-flex align-items-center justify-content-center">
              {reviewloading ? (
                <Spinner color="primary" />
              ) : (
                <p style={{ maxHeight: "auto", overflowY: "auto" }}>
                  {generatedText}
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-center text-center">
        <Col xs="8" md="6" lg="4">
          <Button
            onClick={copyToClipboard}
            style={{
              backgroundColor: queMerchData.MerchantsTable.themeColor,
              color: queMerchData.MerchantsTable.textColor,
              fontSize: "18px",
              padding: "10px 10px",
              width: "100%",
            }}
          >
            Copy & Post
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default AIReviewCreation
