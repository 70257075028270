import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const SupportPage = props => {
  document.title = "Support | Lexa - Responsive Bootstrap 5 Admin Dashboard"

  const breadcrumbItems = [
    { title: "PostReview", link: "#" },
    { title: "Dashboard", link: "/dashboard" },
    { title: "Support Page", link: "/support" },
  ]
  const email = localStorage.getItem("email")
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Support", breadcrumbItems)
  }, [])

  const [formData, setFormData] = useState({
    subject: "",
    description: "",
    email: email, // Default email as provided
  })

  const [successMessage, setSuccessMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (formData.subject && formData.description) {
      try {
        const response = await fetch("https://api.postaireview.com/support", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            subject: formData.subject,
            description: formData.description,
          }),
        })

        if (response.ok) {
          const data = await response.json()
          setSuccessMessage("Your support request has been sent successfully.")
          setErrorMessage("")
          setFormData({ subject: "", description: "", email: email })
          console.log("Response Data:", data)
        } else {
          const errorData = await response.json()
          setErrorMessage(errorData.message || "Something went wrong.")
          setSuccessMessage("")
        }
      } catch (error) {
        console.error("Error:", error)
        setErrorMessage(
          "Failed to send support request. Please try again later.",
        )
        setSuccessMessage("")
      }
    } else {
      setErrorMessage("Please fill in all fields before submitting.")
      setSuccessMessage("")
    }
  }

  return (
    <React.Fragment>
      <Row className="justify-content-center mt-5">
        <Col md={8} lg={6}>
          <Card>
            <CardBody>
              <CardTitle tag="h4" className="text-center mb-4">
                How Can We Help You?
              </CardTitle>
              {successMessage && (
                <Alert color="success">{successMessage}</Alert>
              )}
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              <Form onSubmit={handleSubmit}>
                {/* <FormGroup>
                  <Label for="email">Email ID</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleChange}
                    required
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="subject">
                    Write a short description about your query.
                  </Label>
                  <Input
                    type="text"
                    name="subject"
                    id="subject"
                    placeholder="Enter the subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder="Describe your issue"
                    rows="5"
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <Button color="primary" type="submit" className="w-100">
                  Send
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(SupportPage)
